import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full'
  },
  {
    path: 'labo',
    loadChildren: () => import('./pages/labo/labo.module').then( m => m.LaboPageModule)
  },
  {
    path: 'demande-analyse',
    loadChildren: () => import('./pages/demande-analyse/demande-analyse.module').then( m => m.DemandeAnalysePageModule)
  },
  {
    path: 'prt-demande-analyse',
    loadChildren: () => import('./pages/prt-demande-analyse/prt-demande-analyse.module').then( m => m.PrtDemandeAnalysePageModule)
  },
  {
    path: 'accueil',
    loadChildren: () => import('./pages/accueil/accueil.module').then( m => m.AccueilPageModule)
  },
  {
    path: 'caisse',
    loadChildren: () => import('./pages/caisse/caisse.module').then( m => m.CaissePageModule)
  },
  {
    path: 'facture',
    loadChildren: () => import('./pages/facture/facture.module').then( m => m.FacturePageModule)
  },
  {
    path: 'prt-quittance-analyse',
    loadChildren: () => import('./pages/prt-quittance-analyse/prt-quittance-analyse.module').then( m => m.PrtQuittanceAnalysePageModule)
  },
  {
    path: 'prt-resultat-analyse',
    loadChildren: () => import('./pages/prt-resultat-analyse/prt-resultat-analyse.module').then( m => m.PrtResultatAnalysePageModule)
  },
  {
    path: 'resultat-analyse',
    loadChildren: () => import('./pages/resultat-analyse/resultat-analyse.module').then( m => m.ResultatAnalysePageModule)
  },
  {
    path: 'modal-resultat-input',
    loadChildren: () => import('./pages/modal-resultat-input/modal-resultat-input.module').then( m => m.ModalResultatInputPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
